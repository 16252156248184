import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ShopLayout, Row } from '../../components/Layout';
import StyledSmile from '../../components/StyledSmile';

import MailingListSignup from '../../components/MailingListSignup';
import SocialLinks from '../../components/SocialLinks';
import { apiUpdate } from '../../services/sendToApi';

const StyledSectionHeader = styled.h2`
  margin-bottom: 8px;
  text-align:center;
`;

const StyledText = styled.p`
  margin: 20px 0px 4px 0px;
  font-size: 24px;
  cursor: pointer;
`;

function Success({ location }) {
  const { t } = useTranslation();
  const { state = {} } = location;
  const { id } = state;

  useEffect(() => {
    apiUpdate(id, { mood: 'happy' });
  }, []);

  return (
    <ShopLayout>
      <Row>
        <StyledSmile />
      </Row>
      <Row>
        <StyledSectionHeader>
          {t('successPage.successMessage')}
        </StyledSectionHeader>
      </Row>
      <Row>
        <StyledText>
          {t('successPage.emailDisclaimer.0')}
          <br />
          <br />
          {t('successPage.emailDisclaimer.1')}
        </StyledText>
      </Row>
      <MailingListSignup state={state} />
      <SocialLinks />
    </ShopLayout>
  );
}

export default Success;
